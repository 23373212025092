import {forwardRef} from 'react'

interface IconType {
  type: 'flower' | 'sun' | 'octa' | 'w' | 'cross' | 'circle'
  className?: string
  props?: any
}

export default forwardRef(function Icon({type = 'flower', className, ...props}, ref: IconType) {
  if (type == 'flower') {
    return (
      <svg
        ref={ref}
        className={className}
        viewBox="0 0 99 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M99 47.4984C99 40.531 93.1119 34.881 85.8509 34.881H81.2417L84.5006 31.7539C89.6356 26.8265 89.6356 18.8374 84.5006 13.91C79.3655 8.98266 71.0398 8.98266 65.9048 13.91L62.6459 17.0371V12.6143C62.6492 5.64998 56.7611 0 49.5 0C42.2389 0 36.3509 5.64998 36.3509 12.6175V17.0403L33.092 13.9131C27.957 8.98577 19.6312 8.98577 14.4962 13.9131C9.36118 18.8405 9.36118 26.8296 14.4962 31.757L17.7551 34.8841H13.1459C5.88807 34.881 0 40.531 0 47.4984C0 54.4659 5.88806 60.1159 13.1491 60.1159H17.7583L14.4994 63.243C9.36443 68.1704 9.36443 76.1595 14.4994 81.0868C19.6345 86.0142 27.9602 86.0142 33.0952 81.0868L36.3541 77.9597V82.3825C36.3541 89.35 42.2422 95 49.5032 95C56.7643 95 62.6524 89.35 62.6524 82.3825V77.9597L65.9113 81.0868C71.0463 86.0142 79.372 86.0142 84.5071 81.0868C89.6421 76.1595 89.6421 68.1704 84.5071 63.243L81.2482 60.1159H85.8574C93.1119 60.1159 99 54.4659 99 47.4984Z"
          fill="inherit"
        />
      </svg>
    )
  }
  if (type == 'sun') {
    return (
      <svg
        ref={ref}
        className={className}
        viewBox="0 0 109 109"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M109 54.5C90.8321 59.5162 89.8174 64.2957 104.719 75.7156C86.1423 72.9906 83.4818 76.906 93.0408 93.0372C76.9059 83.4782 72.9941 86.1387 75.7191 104.715C64.2957 89.8174 59.5162 90.8321 54.5 109C49.4838 90.8321 44.7043 89.8174 33.2844 104.719C36.0094 86.1423 32.094 83.4818 15.9628 93.0408C25.5218 76.9059 22.8613 72.9941 4.2847 75.7191C19.1826 64.2957 18.1679 59.5162 0 54.5C18.1679 49.4838 19.1826 44.7043 4.28112 33.2844C22.8577 36.0094 25.5182 32.094 15.9592 15.9628C32.094 25.5218 36.0059 22.8613 33.2809 4.2847C44.7043 19.1826 49.4838 18.1679 54.5 0C59.5162 18.1679 64.2957 19.1826 75.7156 4.28112C72.9906 22.8577 76.906 25.5182 93.0372 15.9592C83.4782 32.094 86.1387 36.0059 104.715 33.2809C89.8174 44.7043 90.8321 49.4838 109 54.5Z"
          fill="inherit"
        />
      </svg>
    )
  }
  if (type == 'circle') {
    return (
      <svg
        ref={ref}
        className={className}
        viewBox="0 0 146 146"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle cx="73" cy="73" r="73" fill="inherit" />
      </svg>
    )
  }
  if (type == 'octa') {
    return (
      <svg
        ref={ref}
        className={className}
        viewBox="0 0 132 123"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M106.276 8.91392L40.6117 0.479362L0.466492 53.1938L25.9866 114.339L91.6514 122.773L131.796 70.0629L106.276 8.91392Z"
          fill="inherit"
        />
      </svg>
    )
  }

  if (type == 'w') {
    return (
      <svg
        ref={ref}
        className={className}
        viewBox="0 0 45 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M0 27.943L7.28506 32.8916L20.3392 21.5043L20.5458 21.6444L14.8805 38.0515L22.1656 43L45 24.9549L38.2311 20.357L24.3839 31.3566L24.1772 31.2157L29.9643 14.7416L23.9706 10.6696L10.8461 22.1609L10.6403 22.0208L15.7028 5.05422L8.26258 0L0 27.943Z"
          fill="inherit"
        />
      </svg>
    )
  }

  if (type == 'cross') {
    return (
      <svg
        ref={ref}
        className={className}
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <line x1="1.70711" y1="1.29289" x2="14.435" y2="14.0208" stroke="black" strokeWidth="2" />
        <line x1="1.29289" y1="14.0208" x2="14.0208" y2="1.29289" stroke="black" strokeWidth="2" />
      </svg>
    )
  }

  return <>''</>
})
